<template>
    <div>
        <h1 class="page__title">Заявки</h1>

        <ContentSearch />

        <ContentTable
            :inverse-toggle-icons="true"
            :table-actions="tableActions"
            :headers="tableHeaders"
            :table-rows="tableData"
            :meta="pagination"
            :loading="loading"
        />
    </div>
</template>

<script>
import { errorResponse } from '@/mixins/form/form-mixin';
import ContentTable from '@/components/tables/ContentTable';
import ContentSearch from '@/components/base/ContentSearch';
import { mapGetters } from 'vuex';

export default {
    components: { ContentSearch, ContentTable },
    mixins: [errorResponse],
    data() {
        return {
            loading: false,
            affiliate: false,
            modalOpen: false,
            modalType: null,
            modalTitle: null,
            modalCallback: null,
            modalData: null,
            tableActions: null,
            tableHeaders: [
                { field: 'id', label: '#' },
                { field: 'name', label: 'Имя' },
                { field: 'phone', label: 'Телефон' },
                { field: 'email', label: 'Email' },
                { field: 'type', label: 'Тип' },
                { field: 'method_of_contact', label: 'Способ связи' },
                { field: 'affiliate_name', label: 'Филиал' },
            ],
            tableData: [],
            pagination: null,
            affiliates: [],
        };
    },
    async created() {
        await this.fetchData(this.getQuery());
    },
    computed: {
        ...mapGetters({
            getUser: 'user/getUser',
        }),
    },
    methods: {
        getQuery(query = this.$route.query) {
            const affiliateIds =
                this.getUser && this.getUser.assigned_affiliates && this.getUser.assigned_affiliates.length > 0
                    ? this.getUser.assigned_affiliates.join(',')
                    : '';
            return this.isSuperAdmin ? query : Object.assign({}, query, { affiliate_id: affiliateIds });
        },
        async fetchData(params) {
            try {
                this.loading = true;
                const { data } = await this.axios.get('/pleas', { params });
                this.tableData = data.data;
                this.pagination = data.meta;
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.error('cant fetch data: ' + ex);
            }
        },
    },
    async beforeRouteUpdate(to, from, next) {
        const query = this.getQuery(to.query);
        await this.fetchData({ ...query });
        next();
    },
};
</script>

<style></style>
